import React from "react";
import DynamicSysFeature from "./DynamicSysFeature";
import one from "../assests/bulk1.jpg";
import two from "../assests/bulk.jpg";
import "./features.css";

const SystemFeature = () => {
  return (
    <div className="features">
      <DynamicSysFeature
        className="feature"
        text={
          <ul>
            <li>Patient Registration</li>
            <li>Appointment Management</li>
            <li>Treatment Plan</li>
            <li>Patient Notes</li>
            <li>Procedures/Treatment</li>
            <li>Prescriptions/Medicine-(Indoor/Outdoor)</li>
            <li>Lab Order Management</li>
            <li>Billing Management</li>
            <li>Due Billing Management</li>
            <li>Daily Transaction</li>
            <li>Daily Account-Daily Event Checking</li>
            <li>Misc Payment & Receive</li>
            <li>Misc purchase & Payment system</li>
            <li>Misc Reports</li>
            <li>Settings</li>
            <li>Misc Chart Reports</li>
            <li>Support Barcode Reader</li>
            <li> Instance Photo Capture via Webcam</li>
            <li> Auto Generate Daily Income/Expenses Reports</li>
            <li>Approval Options for billing</li>
            <li>Various Other Reports</li>
            <li>Doctor Mode Options</li>
            <li>Treatment Task Management</li>
          </ul>
        }
        img1={one}
        img2={two}
        btnText="view detail"
        url="#"
        btnClass="view-detail"
      />
    </div>
  );
};
export default SystemFeature;
