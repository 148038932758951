import React from "react";
import DynamicImageContainer from "../Component/DynamicImageContainer";
import "./imageContainer.css";
import one from "../assests/11.jpg";
import two from "../assests/bulk.jpg";
import three from "../assests/bulk1.jpg";
import four from "../assests/overall.jpg";

const ImageContainer = () => {
  // Define separate click handlers for each image
  const handleClickOne = () => {
    window.open("https://facebook.com", "_blank");
  };

  const handleClickTwo = () => {
    window.open("https://twitter.com", "_blank");
  };

  const handleClickThree = () => {
    window.open("https://youtube.com", "_blank");
  };

  const handleClickFour = () => {
    window.open("https://instagram.com", "_blank");
  };
  return (
    <div className="image-container">
      <DynamicImageContainer img={one} onClick={handleClickOne} />
      <DynamicImageContainer img={two} onClick={handleClickTwo} />
      <DynamicImageContainer img={three} onClick={handleClickThree} />
      <DynamicImageContainer img={four} onClick={handleClickFour} />
    </div>
  );
};

export default ImageContainer;
