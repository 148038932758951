import React from "react";
import "./productCard.css";

const DynamicProductCard = (props) => {
  return (
    <div className="n-card">
      <div className="n-img">
        <img alt="img" src={props.img} />
      </div>
      <h4>{props.heading}</h4>
      <p>{props.text}</p>
      <a href={props.url} className={props.btnClass}>
        {props.btnText}
      </a>
    </div>
  );
};
export default DynamicProductCard;
