import React from "react";
import "./features.css";

const DynamicSysFeature = (props) => {
  return (
    <div className={props.className}>
      <div className="features">
        <p>{props.text}</p>
      </div>
      <div className="features-img">
        <img alt="img" src={props.img1} />
        <img alt="img" src={props.img2} />
        <a href={props.url} className={props.btnClass}>
          {props.btnText}
        </a>
      </div>
    </div>
  );
};
export default DynamicSysFeature;
