import "./aboutstyle.css";
import DynamicSabsys from "./DynamicSabsys";
import "./sabsys.css";
import one from "../assests/sabsys.jpg";

const Sabsys = () => {
  return (
    <div className="about-container">
      <div className="sabsys">
        <DynamicSabsys
          img={one}
          heading="We provide system management software and tools"
          detail="System management, also known as systems management or IT systems management, refers to the process of overseeing and controlling an organization's information technology (IT) infrastructure and resources to ensure they operate efficiently, securely, and in alignment with the organization's goals and objectives. Effective system management is essential for maintaining the reliability, performance, and security of IT systems."
        />
      </div>
    </div>
  );
};
export default Sabsys;
