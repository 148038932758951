import React from "react";
import "../Routes/dms.css";

const DynamicSystemDetail = (props) => {
  return (
    <div className="product-detail">
      <h1>{props.topic}</h1>
      <h3>{props.product}</h3>
      <h4>{props.topic_detail}</h4>
      <p>{props.defination}</p>
    </div>
  );
};
export default DynamicSystemDetail;
