import React from "react";
import DynamicSystemDetail from "../Component/DynamicSystemDetail";
import SystemFeature from "../Component/SystemFeature";

const DMS = () => {
  return (
    <div className="product-detail">
      <DynamicSystemDetail
        topic="Sabsys -Dental Management System"
        topic_detail="Sabsys Dental Management System is a system software which is based on web-based technology, developed by TanviTech services with lots of long period research and different version software experiments.
        For small and large Dental or hospitals, it is very difficult to find appropriate software in the market to record patient details and treatment process digitally. In the same way, there are difficulties for dental’s doctors to fill out some detailed information because of a lack of medium which causes a hard and poor Data record management system. So, TanviTech services has developed Sabsys Dental Management System software to solve these dental clinic’s problems and make their work paperless with a proper data record management system. The software was released on 2078 Falgun 1 with the succession experiment in a Dental located in kapan.
        "
      />
      <DynamicSystemDetail
        product="What is Web based System?"
        defination="A web-based system is a system which provides access to Data, software system, information using a computer or any device through internet connection from any place. It is a system by which any program can easily access over a network connection.
    "
      />
      <div className="service-appointment">
        <h2>Our Features</h2>
      </div>
      <SystemFeature />
    </div>
  );
};
export default DMS;
