import React, { useState } from "react";
import { NavMenu } from "./NavMenu";
import SabsysLogo from "../Images/SabsysLogo.jpg";
import DropDown from "./Dropdown";
import "./navbarstyle.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  // Define the clicked state variable and its setter function using useState
  const [clicked, setClicked] = useState(false);

  // handleClick function to toggle the 'clicked' state
  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <div>
      <nav className="NavbarItems">
        {/* <Link to="/" className="logo"> */}
        <img src={SabsysLogo} alt="Sabsys"></img>
        {/* </Link> */}

        <div className="hamburger-menu" onClick={handleClick}>
          <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>

        <ul className={clicked ? "nav-menu active" : "nav-menu"}>
          {/* to active the hamburger button             */}
          {NavMenu.map((item, index) => {
            //mapping array from NavMenu.js
            if (item.title === "Service") {
              //making li dynamic
              return (
                <li
                  key={index}
                  onMouseEnter={() => setClicked(true)}
                  onMouseLeave={() => setClicked(false)}
                >
                  <Link className={item.cName} to={item.url}>
                    {/* making url dynamic */}
                    <i className={item.icon}></i>
                    {item.title}
                  </Link>
                  {clicked && <DropDown />}
                </li>
              );
            }
            return (
              //making li dynamic
              <li key={index}>
                <Link className={item.cName} to={item.url}>
                  {/* making url dynamic */}
                  <i className={item.icon}></i>
                  {item.title}
                </Link>
              </li>
            );
          })}
          <button className="signupBtn">Sign Up</button>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
