import "./aboutstyle.css";

const AboutUs = () => {
  return (
    <div className="about-container">
      <h1>Our History</h1>
      <p>
        The Corporate Office of Navitech Pvt.Ltd. Situated at Narephat-32,
        Jadibuti Kathmandu Municipality. TanviTech Pvt.Ltd. is a Leading IT
        company that provide various services and some real life problem solving
        solution maintains uniqueness in its brand and service as a professional
        computer institute in Nepal since its establishment in 2020. It
        basically provides computer training in programming languages like C,C++
        ,Java, ASP.NET, C#, PHP, Oracle, SQL Server, Android and C/C++
      </p>
      <h1>Our Mission</h1>
      <p>
        The Corporate Office of Navitech Pvt.Ltd. Situated at Narephat-32,
        Jadibuti Kathmandu Municipality. TanviTech Pvt.Ltd. is a Leading IT
        company that provide various services and some real life problem solving
        solution maintains uniqueness in its brand and service as a professional
        computer institute in Nepal since its establishment in 2020. It
        basically provides computer training in programming languages like C,C++
        ,Java, ASP.NET, C#, PHP, Oracle, SQL Server, Android and C/C++
      </p>
      <h1>Our Vision</h1>
      <p>
        The Corporate Office of Navitech Pvt.Ltd. Situated at Narephat-32,
        Jadibuti Kathmandu Municipality. TanviTech Pvt.Ltd. is a Leading IT
        company that provide various services and some real life problem solving
        solution maintains uniqueness in its brand and service as a professional
        computer institute in Nepal since its establishment in 2020. It
        basically provides computer training in programming languages like C,C++
        ,Java, ASP.NET, C#, PHP, Oracle, SQL Server, Android and C/C++
      </p>
    </div>
  );
};
export default AboutUs;
