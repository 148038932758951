import React from "react";
import "./service.css";
import Navbar from "../Component/Navbar";
import Hero from "../Component/Hero";
import Footer from "../Component/Footer";
import ProductCard from "../Component/ProductCard";
import DMS from "./DMS";
const Service = () => {
  return (
    <div>
      <Navbar />
      <Hero
        cName="hero"
        heroImg="https://images.unsplash.com/photo-1581090464777-f3220bbe1b8b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80"
        title="SABSYS"
        text="Truly your management designing partner"
        btnText="Let's Begin "
        url="#"
        btnClass="show"
      />
      <ProductCard />
      <DMS />
      <Footer />
    </div>
  );
};
export default Service;
