import React from "react";
import "./productCard.css";
import DynamicProductCard from "./DynamicProductCard";

const ProductCard = () => {
  return (
    <div className="card">
      <h1>Manage your datum and save them for future</h1>
      <p>
        Sabsys is the easiest, most economical way to get your datum managed.
        With a user-friendly interface and cost-effective pricing plans, you can
        effortlessly organize and store your data, ensuring it's secure and
        easily accessible when you need it.
      </p>
      <div className="packagecard">
        <DynamicProductCard
          img="https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8dGVjaG5vbG9neXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=700&q=60"
          heading="Dental Management System"
          text="Gokyo(गोक्यो) Package:This is Medium 18 GB packages of TanviTech Linux Web hosting With an elastic, very safe and secure web hosting....."
          btnText="show more"
          url="#"
          btnClass="show"
        />
        <DynamicProductCard
          img="https://images.unsplash.com/photo-1535223289827-42f1e9919769?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80"
          heading="School Management System"
          text="Phoskundo(फोसकुण्डो) Package: This is Medium 10 GB packages of TanviTech Linux Web hosting With an elastic, very safe and secure web hosting."
          btnText="show more"
          url="#"
          btnClass="show"
        />
        <DynamicProductCard
          img="https://images.unsplash.com/photo-1485827404703-89b55fcc595e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
          heading="Restaurant Management System"
          text="Gokyo(गोक्यो) Package:This is Medium 18 GB packages of TanviTech Linux Web hosting With an elastic, very safe and secure web hosting....."
          btnText="show more"
          url="#"
          btnClass="show"
        />
        <DynamicProductCard
          img="https://images.unsplash.com/photo-1485827404703-89b55fcc595e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
          heading="Inventory Management System"
          text="Gokyo(गोक्यो) Package:This is Medium 18 GB packages of TanviTech Linux Web hosting With an elastic, very safe and secure web hosting."
          btnText="show more"
          url="#"
          btnClass="show"
        />
      </div>
    </div>
  );
};
export default ProductCard;
