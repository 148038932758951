import React from "react";
import Navbar from "../Component/Navbar";
import Hero from "../Component/Hero";
import AboutUs from "../Component/AboutUs";

const About = () => {
  return (
    <div id="about">
      <Navbar />
      <Hero
        cName="hero-about"
        heroImg="https://images.unsplash.com/photo-1634143407677-e19f75512b69?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1615&q=80"
        title="About"
        // text="Choose Your Favourite Destination"
        btnText="Travel Plan"
        url="#"
        btnClass="hide"
      />
      <AboutUs />
    </div>
  );
};
export default About;
