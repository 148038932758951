import React from "react";

const DynamicImageContainer = ({ img, onClick }) => {
  const handleImageClick = () => {
    // Call the provided onClick function when the image is clicked
    onClick();
  };
  return (
    <div>
      <img src={img} alt="image" onClick={handleImageClick} />
    </div>
  );
};

export default DynamicImageContainer;
