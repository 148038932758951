import React from "react";

const DynamicSabsys = (props) => {
  return (
    <div className="sabsys">
      <img src={props.img} alt="image" />
      <p>
        <h2>{props.heading}</h2>
        {props.detail}
      </p>
    </div>
  );
};
export default DynamicSabsys;
