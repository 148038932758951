import "./footerstyle.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="top">
        <div>
          <h1>TanviTech</h1>
          <p>TanviTech Web Hosting</p>
        </div>
        <div>
          <a href="/">
            <i className="fa-brands fa-facebook-square"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-instagram-square"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-twitter-square"></i>
          </a>
        </div>
      </div>
      <div className="bottom">
        <div>
          <h4>Useful Links</h4>
          <a href="/">Domains</a>
          <a href="/">Web Hosting</a>
          <a href="/">About Us</a>
          <a href="/">Contact</a>
        </div>
        <div>
          <h4>Useful Links</h4>
          <a href="/">Domains</a>
          <a href="/">Web Hosting</a>
          <a href="/">About Us</a>
          <a href="/">Contact</a>
        </div>
        <div>
          <h4>Useful Links</h4>
          <a href="/">Domains</a>
          <a href="/">Web Hosting</a>
          <a href="/">About Us</a>
          <a href="/">Contact</a>
        </div>
        <div>
          <h4>Useful Links</h4>
          <a href="/">Domains</a>
          <a href="/">Web Hosting</a>
          <a href="/">About Us</a>
          <a href="/">Contact</a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
