import React from "react";

const Contact = () => {
  return (
    <div id="contact">
      <h1>Contact Us</h1>
    </div>
  );
};
export default Contact;
