import React from "react";
import Navbar from "../Component/Navbar";
import Hero from "../Component/Hero";
import Sabsys from "../Component/Sabsys";
import Container from "../Component/Container";
import "./home.css";
import ImageContainer from "../Component/ImageContainer";
import Footer from "../Component/Footer";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero
        cName="hero"
        heroImg="https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1471&q=80"
        title="SABSYS"
        text="Efficiency in Every Operation, Excellence in Every System."
        btnText="Travel Plan"
        url="#"
        btnClass="hide"
      />
      <Sabsys />
      <Container />
      <div className="appointment">
        <h2>Manage Your System With Us</h2>
        <p>Provide Safety To Your System</p>
        <div className="appointment-btn">
          <a href="/signup">Let's Begin</a>
        </div>
      </div>
      <ImageContainer />
      <Footer />
    </div>
  );
};
export default Home;
