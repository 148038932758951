import React from "react";

const DynamicProfile = (props) => {
  return (
    <div className="container-box">
      <div className="container-box-mobile">
        <h4>{props.topic}</h4>
        <p>{props.detail}</p>
        <a href={props.url} className={props.btnClass}>
          {props.btnText}
        </a>
      </div>
    </div>
  );
};
export default DynamicProfile;
